<template>
  <div class="fixed-top">
    <Header />
    <div id="tophead" v-if="showheadDiv">
      <div class="header-bot-sec">
        <div class="container">
          <div class="row">
           <div class="rdo">
            <form action="#">
              <p class="first-rasecc">
                <input
                  type="radio"
                  id="quote"
                  name="radio-group"
                  :checked="isQuote"
                  disabled
                />
                <label for="quote" v-bind:class="{ active: isQuote }"
                  ><span>QUOTE</span></label
                >
              </p>
              <p class="first-rasecc1">
                <input
                  type="radio"
                  id="userinfo"
                  name="radio-group"
                  :checked="isinfo"
                  disabled
                />
                <label for="userinfo" v-bind:class="{ active: isinfo }"
                  ><span>YOUR INFO</span></label
                >
              </p>
              <p class="first-rasecc2">
                <input
                  type="radio"
                  id="payment"
                  name="radio-group"
                  :checked="ispayment"
                  disabled
                />
                <label for="payment" v-bind:class="{ active: ispayment }"
                  ><span>PAYMENT</span></label
                >
              </p>
              <p>
                <input
                  type="radio"
                  id="wellcome"
                  name="radio-group"
                  :checked="iswelcome"
                  disabled
                />
                <label for="wellcome" v-bind:class="{ active: iswelcome }">
                  <span>WELCOME</span>
                </label>
              </p>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot />

  <!-- Single policy -->

  <div
    class="container"
    v-if="petDetails.length === 1 && policyNumber.length === 1"
  >
    <div class="row">
      <div class="ThankYou-Section">
        <h2>Thank You!</h2>
        <img src="../assets/checkright.png" class="img-responsive CheckRight" />
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="border-secTh">
              <p>
                Your account has been activated and you first payment of
                <span>${{ amount }}</span> has been cleared for processing.
                <span
                  >Please print or keep a copy of the following
                  information:</span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="TableIdSec">
          <div class="row">
            <div class="col-md-6">
              <table>
                <tbody>
                  <tr>
                    <td>Transaction Date:</td>
                    <td>
                      <strong>{{ date }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>Customer ID:</td>
                    <td>
                      <strong>{{ customerID }}</strong>
                    </td>
                  </tr>

                  <tr v-for="(pets, key) in petDetails" :key="key">
                    <td>Pet Name:</td>
                    <td>
                      <strong>{{ pets.PetName }}</strong>
                    </td>
                  </tr>

                  <tr v-for="(policyNumbers, key) in policyNumber" :key="key">
                    <td>Reference:</td>
                    <td>
                      <strong>{{ policyNumbers.policyNumber }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="ThankYou-Bottext">
            <p>
              You will be receiving a Confirmation Email with more information
              shortly.
            </p>

            <p>
              If you have not received an email within the next 30 minutes,
              please contact one of our PHI Direct Care Representatives at
              <strong>1-855-600-7072,</strong> by chat on our
              <span>
                <a
                  style="color: #6ba43a"
                  href="https://www.phidirect.com/"
                  target="_blank"
                  >website</a
                > </span
              >, or email us at
              <span
                ><a style="color: #6ba43a" v-bind:href="'mailto:' + email">{{
                  email
                }}</a></span
              >
            </p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="meantimeimg">
            <img src="../assets/maintanceimg.png" />
            <p>
              In the meantime, Please download a
              <br />copy of the
              <span>
                <a
                  style="color: #6ba43a"
                  href="
https://www.phidirect.com/hubfs/PHI-Direct-Documents/PHI-CA-Policy-Document-Online.pdf"
                  target="_blank"
                  >PHI Direct Policy</a
                > </span
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Multiple policy -->

  <div
    class="container"
    v-if="petDetails.length > 1 && policyNumber.length > 1"
  >
    <div class="row">
      <div class="ThankYou-Section">
        <h2>Thank You!</h2>
        <img src="../assets/checkright.png" class="img-responsive CheckRight" />

        <div class="row">
          <div class="col-md-12 text-center">
            <div class="border-secTh">
              <p>
                Your account has been activated and you first payment of
                <span>${{ amount }}</span> has been cleared for processing.
                <span
                  >Please print or keep a copy of the following
                  information:</span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="TableIdSec MuliplePetTable">
          <div class="row">
            <div class="col-md-6">
              <table>
                <tbody>
                  <tr>
                    <td>Transaction Date:</td>
                    <td>
                      <strong>{{ date }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>Customer ID:</td>
                    <td>
                      <strong>{{ customerID }}</strong>
                    </td>
                  </tr>

                  <tr v-for="(pets, key) in petDetails" :key="key">
                    <td>Pet Name:</td>
                    <td>
                      <strong>{{ pets.PetName }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-6 Mulipletablesec">
              <table>
                <tbody>
                  <tr v-for="(policyNumbers, key) in policyNumber" :key="key">
                    <td>Reference:</td>
                    <td>
                      <strong>{{ policyNumbers.policyNumber }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="ThankYou-Bottext">
            <p>
              You will be receiving a Confirmation Email with more information
              shortly.
            </p>

            <p>
              If you have not received an email within the next 30 minutes,
              please contact one of our PHI Direct Care Representatives at
              <strong>1-855-600-7072,</strong> by chat on our
              <span>
                <a
                  style="color: #6ba43a"
                  href="https://www.phidirect.com/"
                  target="_blank"
                  >website</a
                > </span
              >, or email us at
              <span
                ><a style="color: #6ba43a" v-bind:href="'mailto:' + email">{{
                  email
                }}</a></span
              >
            </p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="meantimeimg">
            <img src="../assets/maintanceimg.png" />
            <p>
              In the meantime, Please download a
              <br />copy of the
              <span>
                <a
                  style="color: #6ba43a"
                  href="
https://www.phidirect.com/hubfs/PHI-Direct-Documents/PHI-CA-Policy-Document-Online.pdf"
                  target="_blank"
                  >PHI Direct Policy</a
                > </span
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/header.vue";

export default {
  title: "Welcome to PHI Direct – Fluff-free Pet Health Insurance",
  components: {
    Header,
  },
  data: () => ({
    isQuote: false,
    isinfo: false,
    ispayment: false,
    iswelcome: true,
    showheadDiv: true,
    policyNumber: [],
    petDetails: [],
    amount: 0,
    customerID: "",
    date: Date,
    email: "care@phidirect.com",
  }),
  async beforeMount() {
    this.scrollToTop();
    if (!localStorage.getItem("BuyPolicyData")) {
      this.$router.push({ name: "Home" });
    }

    const current = new Date();
    this.date = `${
      current.getMonth() + 1
    }/${current.getDate()}/${current.getFullYear()}`;

    let buyPolicyRecord = localStorage.getItem("BuyPolicyData");
    let policyNumbers = JSON.parse(buyPolicyRecord);
    policyNumbers.policy.forEach((policys) => {
      this.customerID = policys.customerID;
      this.policyNumber.push(policys);
    });

    let petsDetail = localStorage.getItem("PetDetails");
    this.petDetails = JSON.parse(petsDetail);

    let amounts = localStorage.getItem("PaymentAmount");
    this.amount = JSON.parse(amounts);
  },
  mounted() {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    sendHomePage() {
      this.$router.push({ name: "Home" });
      localStorage.removeItem("BuyPolicyData");
      localStorage.removeItem("PetDetails");
      localStorage.removeItem("Email");
      localStorage.removeItem("PaymentAmount");
    },
  },
};
</script>
<style>
.ThankYou-Section {
  max-width: 730px;
  margin: 9rem auto 0;
}
.CheckRight {
  width: 12%;
  margin: 0 auto 30px;
  display: block;
}
.ThankYou-Section h2 {
  font-size: 3rem;
  color: #326820;
  font-family: system-ui;
  font-weight: 700;
  text-align: center;
}
.border-secTh {
  border-bottom: 1px #6ba43a solid;
}
.border-secTh p {
  margin-bottom: 25px;
}
.border-secTh p span {
  font-weight: bold;
}
.TableIdSec table tr td {
  padding-left: 10px;
  padding-bottom: 2px;
  border: none;
  text-align: left;
}
.TableIdSec {
  border-bottom: 1px #6ba43a solid;
  padding: 5px 0 15px;
}
.TableIdSec table {
  margin: 0 auto;
  border: none;
}
.ThankYou-Bottext {
  text-align: center;
  padding: 20px 0px 10px;
}
.ThankYou-Bottext p span {
  color: #6ba43a;
}
.meantimeimg {
  text-align: center;
  margin: 30px 0;
}
.meantimeimg p {
  display: inline-block;
  text-align: left;
}
.meantimeimg img {
  width: 16%;
  margin-right: 20px;
  position: relative;
  bottom: 16px;
}
.meantimeimg p span {
  color: #6ba43a;
}
.TableIdSec .col-md-6 {
  margin: 0 auto;
}
.MuliplePetTable table {
  margin: initial;
}
.Mulipletablesec table {
  margin-top: 80px;
}

@media (max-width: 767px) {
  .meantimeimg {
    margin: 0 0 10px;
  }
  .Mulipletablesec table {
    margin-top: 0;
  }
  .MuliplePetTable table {
    margin: 0 auto;
  }
  .TableIdSec table tr td {
    text-align: center;
  }
  .ThankYou-Section {
    padding: 0 10px;
  }
}

@media (max-width: 389px) {
  .meantimeimg img {
    position: relative;
    top: 0;
    margin-bottom: 5px;
  }
}
</style>
